"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectCost = void 0;
/**
 * An enumeration.
 */
var DirectCost;
(function (DirectCost) {
    DirectCost["DRC_DIRECT"] = "drc_direct";
    DirectCost["DRC_INDIRECT"] = "drc_indirect";
})(DirectCost || (exports.DirectCost = DirectCost = {}));
