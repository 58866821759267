import { Box, Container, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import React, { useContext } from "react";
import Markdown from "markdown-to-jsx";
import CircularLoading from "../components/loading/CircularLoading";
import { AppContext } from "../AppRouter";

const AdvisorContainer = ({
    title,
    subtitle,
    actions,
    showTitle,
    loading,
    loadingLabel,
    dataCyProp,
    minWidth = "auto",
    maxWidth,
    maxHeight = "auto",
    children,
    noSpacingAfterTitle = false,
}) => {
    const { config } = useContext(AppContext);

    return (
        <>
            <Helmet>
                <title>
                    {config.i18n.page.main}
                    {title ? ` - ${title}` : ""}
                </title>
            </Helmet>
            <Container
                data-cy={dataCyProp}
                maxWidth={maxWidth || "lg"}
                sx={{
                    minWidth: minWidth,
                    maxHeight: maxHeight,
                    display: "flex",
                    flexDirection: "column",
                    py: 4,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ mb: noSpacingAfterTitle ? 0 : 2 }}
                >
                    <Box display="flex" flexDirection="column">
                        { showTitle
                            ? (
                                <Typography variant="h4" align="left" data-cy="title" sx={{ mb: subtitle ? 0 : 2 }}>
                                    {title}
                                </Typography>
                                )
                            : null}
                        { subtitle && !loading
                            ? (
                                <Typography variant="subtitle1" align="left" fontSize={18} color="#666666" data-cy="subtitle">
                                    <Markdown>{subtitle}</Markdown>
                                </Typography>
                                )
                            : null}
                    </Box>
                    <Box>
                        { !loading ? actions : null}
                    </Box>
                </Box>
                { loading ? <CircularLoading position="absolute" height="800px" label={loadingLabel} /> : children}
            </Container>
        </>
    );
};

AdvisorContainer.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    actions: PropTypes.element,
    showTitle: PropTypes.bool,
    loading: PropTypes.bool,
    loadingLabel: PropTypes.string,
    dataCyProp: PropTypes.string,
    minWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
    children: PropTypes.any,
    noSpacingAfterTitle: PropTypes.bool,
};

AdvisorContainer.defaultProps = {
    showTitle: true,
};

export default AdvisorContainer;
