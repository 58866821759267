import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import DimensionPicker from "./DimensionPicker";
import DimensionBox from "../DimensionBox";
import { Stack } from "@mui/system";
import { AppContext } from "../../AppRouter";

const DimensionSelector = ({
    dataCyProp,
    title,
    documentDimensions,
    dimensionFilters,
    analysisDimensionFilters,
    dimensions,
    metadata,
    parameters,
    disabled,
    onUpdate,
    onDelete,
}) => {
    const { config } = useContext(AppContext);

    const [isAddMode, setIsAddMode] = useState(false);

    // render already applied dims.
    const appliedDims = dimensions.map(dimension =>
        (
            <DimensionPicker
                key={`${dimension}-box`}
                documentDimensions={documentDimensions}
                dimension={dimension}
                analysisDimensionFilters={analysisDimensionFilters}
                dimensionFilters={dimensionFilters}
                parameters={parameters}
                disabled={disabled}
                onUpdate={onUpdate}
                onDelete={onDelete}
                metadata={metadata}
            />
        ));

    return (
        <DimensionBox
            dataCyProp={dataCyProp}
            title={title}
            collapsible
            isClosed={dimensions.length === 0 && isEmpty(dimensionFilters)}
        >
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1.5}>
                {appliedDims}

                {isAddMode
                    ? (
                        <DimensionPicker
                            documentDimensions={documentDimensions}
                            dimensionFilters={dimensionFilters}
                            parameters={parameters}
                            disabled={disabled}
                            onUpdate={(dimension, dimensionFilters, metadata) => {
                                onUpdate(dimension, dimensionFilters, metadata);
                                setIsAddMode(false);
                            }}
                            onDelete={() => setIsAddMode(false)}
                            metadata={metadata}
                        />
                        )
                    : null}

                <Button
                    data-cy="add_dimension_button"
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={isAddMode || disabled}
                    onClick={() => setIsAddMode(true)}
                >
                    {config.i18n.button.add_filter}
                </Button>
            </Stack>
        </DimensionBox>
    );
};

DimensionSelector.propTypes = {
    dataCyProp: PropTypes.string,
    title: PropTypes.string,
    documentDimensions: PropTypes.object,
    analysisDimensionFilters: PropTypes.object,
    dimensionFilters: PropTypes.object,
    dimensions: PropTypes.array,
    metadata: PropTypes.object,
    parameters: PropTypes.object,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default DimensionSelector;
