import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { formatDateTime, formatYearMonthDay, formatYearMonthLong } from "../../util/formatter";
import AdvisorContainer from "../../layout/AdvisorContainer";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { AppContext } from "../../AppRouter";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Collector = () => {
    const { client, config, notify, user } = useContext(AppContext);
    const navigate = useNavigate();

    const [collectors, setCollectors] = useState();

    const [collectorToDelete, setCollectorToDelete] = useState();
    const [deleting, setDeleting] = useState(false);

    const columns = [
        {
            field: "name",
            headerName: config.i18n.collector.name,
            flex: 6,
            sortable: false,
        },
        {
            field: "start_date",
            headerName: config.i18n.collector.start_date,
            flex: 2,
            sortable: false,
            renderCell: params => `${formatYearMonthLong(params.value, config.locale?.language)}`,
        },
        {
            field: "end_date",
            headerName: config.i18n.collector.end_date,
            flex: 2,
            sortable: false,
            renderCell: params => `${formatYearMonthLong(params.value, config.locale?.language)}`,
        },
        {
            field: "cutoff_date",
            headerName: config.i18n.collector.cutoff_date,
            flex: 2,
            sortable: false,
            renderCell: params => `${formatYearMonthLong(params.value, config.locale?.language)}`,
        },
        {
            field: "created_at",
            headerName: config.i18n.collector.created_at,
            flex: 2,
            sortable: false,
            renderCell: params => (
                <span title={`${config.i18n.collector.created_at} ${formatDateTime(params.value, config.locale?.language)} by ${params.row.created_by_name}`}>
                    {formatYearMonthDay(params.value, config.locale?.language)}
                </span>
            ),
        },
        {
            field: "synced_at",
            headerName: config.i18n.collector.synced_at,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.value ? (
                    <span title={`${config.i18n.collector.synced_at} ${formatDateTime(params.value, config.locale?.language)} by ${params.row.synced_by_name}`}>
                        {formatYearMonthDay(params.value, config.locale?.language)}
                    </span>
                ) : null;
            },
        },
        {
            field: "commited_at",
            headerName: config.i18n.collector.commited_at,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.value ? (
                    <span title={`${config.i18n.collector.commited_at} ${formatDateTime(params.value, config.locale?.language)} by ${params.row.commited_by_name}`}>
                        {formatYearMonthDay(params.value, config.locale?.language)}
                    </span>
                ) : null;
            },
        },
        {
            field: "actions",
            flex: 0,
            sortable: false,
            type: "actions",
            getActions: params => [
                <Tooltip key="edit" title={config.i18n.button.edit}>
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={config.i18n.button.edit}
                        disabled={!params.row.permission?.READ || !params.row.permission?.UPDATE}
                        data-cy="edit"
                        color="info"
                        component={RouterLink}
                        to={`/collector/${params.id}/edit`}
                    />
                </Tooltip>,
                <Tooltip key="clone" title={config.i18n.button.clone}>
                    <GridActionsCellItem
                        icon={<ContentCopyIcon />}
                        label={config.i18n.button.clone}
                        disabled={!user.permissions?.COLLECTOR?.CREATE || !params.row.permission?.READ}
                        data-cy="clone"
                        color="info"
                        component={RouterLink}
                        to={`/collector/${params.id}/clone`}
                    />
                </Tooltip>,
                <Tooltip key="delete" title={config.i18n.button.delete}>
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={config.i18n.button.delete}
                        disabled={!params.row.permission?.DELETE}
                        data-cy="delete"
                        color="error"
                        onClick={() => {
                            setCollectorToDelete(params.row);
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    useEffect(() => {
        client.collector.collectorListCollectors()
            .then((collectors) => {
                setCollectors(collectors.response);
            })
            .catch((error) => {
                setCollectors(null);
                notify.error(error, "collector.fetch");
            });
    }, []);

    return (
        <AdvisorContainer
            title={config.i18n.collector.list.title}
            loading={!collectors}
            loadingLabel={config.i18n.collector.loading}
            actions={(
                <Button
                    data-cy="collector-new_button"
                    variant="contained"
                    disabled={!user.permissions?.COLLECTOR?.CREATE}
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to="/collector/create"
                    title={config.i18n.button.new}
                >
                    {config.i18n.button.new}
                </Button>
            )}
        >
            {
                collectorToDelete
                    ? (
                        <Dialog
                            maxWidth="xs"
                            open={!!collectorToDelete}
                        >
                            <DialogTitle>
                                <Box display="flex" alignItems="center">
                                    <WarningIcon color="warning" sx={{ marginRight: 1 }} />
                                    {config.i18n.dialog.delete_collector_title}
                                </Box>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    {config.i18n.dialog.delete_collector + " "}
                                    <Box component="span" fontWeight="fontWeightMedium">{collectorToDelete.name}</Box>
                                    ?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={deleting} onClick={() => setCollectorToDelete(null)}>
                                    {config.i18n.button.cancel}
                                </Button>
                                <LoadingButton
                                    loading={deleting}
                                    loadingPosition="center"
                                    onClick={() => {
                                        setDeleting(true);
                                        client.collector.collectorDeleteCollector(collectorToDelete.id)
                                            .then((collector) => {
                                                setCollectors(collectors.filter(el => el.id !== collector.id));
                                                setDeleting(false);
                                                setCollectorToDelete(null);
                                            })
                                            .catch((error) => {
                                                notify.error(error, "collector.delete");
                                                setDeleting(false);
                                                setCollectorToDelete(null);
                                            });
                                    }}
                                    data-cy="collector_dialog-delete"
                                >
                                    {config.i18n.button.delete}
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                        )
                    : null
            }
            <ClickableDataGrid
                rows={collectors}
                columns={columns}
                onRowClick={params => navigate(`/collector/${params.id}`)}
            />
        </AdvisorContainer>
    );
};

export default Collector;
