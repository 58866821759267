"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Operator = void 0;
/**
 * An operator to be applied to two results obtained from a Trio or a Metric
 */
var Operator;
(function (Operator) {
    Operator["SUM"] = "SUM";
    Operator["DELTA"] = "DELTA";
    Operator["RELATIVE_DELTA"] = "RELATIVE_DELTA";
    Operator["RATIO"] = "RATIO";
    Operator["PRODUCT"] = "PRODUCT";
    Operator["AVERAGE"] = "AVERAGE";
    Operator["RATIO_MINUS_ONE"] = "RATIO_MINUS_ONE";
})(Operator || (exports.Operator = Operator = {}));
