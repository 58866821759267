import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { formatDateTime, formatYearMonthDay } from "../../util/formatter";
import AdvisorContainer from "../../layout/AdvisorContainer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { AppContext } from "../../AppRouter";
import SyncIcon from "@mui/icons-material/Sync";
import TaskAlt from "@mui/icons-material/TaskAlt";
import { LoadingButton } from "@mui/lab";

const CollectorEntry = () => {
    const { client, config, notify } = useContext(AppContext);
    const navigate = useNavigate();

    const [entries, setEntries] = useState();
    const [collector, setCollector] = useState();

    const [syncing, setSyncing] = useState(false);
    const [committing, setCommitting] = useState(false);

    const { collectorId } = useParams();

    const columns = [
        {
            field: "owner",
            headerName: config.i18n.collector.list.owner,
            flex: 3,
            sortable: false,
        },
        {
            field: "name",
            headerName: config.i18n.collector.list.name,
            flex: 6,
            sortable: false,
        },
        {
            field: "status",
            headerName: config.i18n.collector.list.status,
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                let icon = null;
                let text = null;

                switch (params.value) {
                    case "PENDING":
                        icon = <CloseIcon style={{ color: "#666" }} />;
                        text = <span style={{ marginLeft: "5px", color: "#666" }}>{config.i18n.collector.status.pending}</span>;
                        break;
                    case "IN_PROGRESS":
                        icon = <SettingsIcon style={{ color: "orange" }} />;
                        text = <span style={{ marginLeft: "5px", color: "orange" }}>{config.i18n.collector.status.in_progress}</span>;
                        break;
                    case "IN_REVIEW":
                        icon = <CheckIcon style={{ color: "dodgerblue" }} />;
                        text = <span style={{ marginLeft: "5px", color: "dodgerblue" }}>{config.i18n.collector.status.in_review}</span>;
                        break;
                    case "APPROVED":
                        icon = <CheckIcon style={{ color: "green" }} />;
                        text = <span style={{ marginLeft: "5px", color: "green" }}>{config.i18n.collector.status.approved}</span>;
                        break;
                    case "READ_ONLY":
                        icon = <SearchIcon style={{ color: "#666" }} />;
                        text = <span style={{ marginLeft: "5px", color: "#666" }}>{config.i18n.collector.status.read_only}</span>;
                        break;
                }
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {icon}
                        {text}
                    </div>
                );
            },
        },
        {
            field: "edited_at",
            headerName: config.i18n.collector.list.edited_at,
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                return params.value ? (
                    <span title={`${config.i18n.collector.list.edited_at} ${formatDateTime(params.value, config.locale?.language)} by ${params.row.edited_by_name} `}>
                        {formatYearMonthDay(params.value, config.locale?.language)}
                    </span>
                ) : null;
            },
        },
        {
            field: "reviewed_at",
            headerName: config.i18n.collector.list.reviewed_at,
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                return params.value ? (
                    <span title={`${config.i18n.collector.list.reviewed_at} ${formatDateTime(params.value, config.locale?.language)} by ${params.row.reviewed_by_name} `}>
                        {formatYearMonthDay(params.value, config.locale?.language)}
                    </span>
                ) : null;
            },
        },
    ];

    const listCollectorEntries = () => {
        client.collector.collectorListCollectorEntries(collectorId, 0, 100)
            .then((entries) => {
                setEntries(entries.response);
            })
            .catch((error) => {
                setEntries([]);
                notify.error(error, "collector.entries.fetch");
            });
    };

    const isPowerUser = () => {
        return collector?.permission?.UPDATE;
    };

    const isReadyToCommit = () => {
        return entries && entries.every(entry => entry.status === "APPROVED" || entry.status === "READ_ONLY");
    };

    useEffect(() => {
        client.collector.collectorGetCollector(collectorId)
            .then((collector) => {
                setCollector(collector);
            })
            .catch((error) => {
                setEntries([]);
                notify.error(error, "collector.fetch");
            });

        listCollectorEntries();
    }, []);

    return (
        <AdvisorContainer
            title={collector?.name}
            loading={!entries || !collector}
            loadingLabel={config.i18n.collector.loading}
            maxWidth="xl"
        >
            <ClickableDataGrid
                pageSize={100} // quick fix to not have pagination
                rows={entries}
                columns={columns}
                hideFooterPagination
                responsive
                onRowClick={params => navigate(`/collector/${collectorId}/${params.id}`)}
            />

            <div style={{ display: "flex", gap: "10px", marginTop: 20 }}>
                {isPowerUser() ? (
                    <LoadingButton
                        loading={syncing}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<SyncIcon />}
                        component={RouterLink}
                        title={config.i18n.button.sync_actuals}
                        disabled={collector.cutoff_date > config.etl.date}
                        onClick={() => {
                            setSyncing(true);
                            client.collector.collectorSyncLedgerDataToCollector(collectorId)
                                .then(() => {
                                    setSyncing(false);
                                    listCollectorEntries();
                                })
                                .catch((error) => {
                                    setSyncing(false);
                                    notify.error(error, "collector.sync_ledger_data_to_collector");
                                });
                        }}
                    >
                        {config.i18n.button.sync_actuals}
                    </LoadingButton>
                ) : null}
                {isPowerUser() ? (
                    <LoadingButton
                        loading={committing}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<TaskAlt />}
                        component={RouterLink}
                        title={config.i18n.button.commit_forecasts}
                        disabled={!isReadyToCommit()}
                        onClick={() => {
                            setCommitting(true);
                            client.collector.collectorCommitCollectorDataToLedger(collectorId)
                                .then(() => {
                                    setCommitting(false);
                                    listCollectorEntries();
                                })
                                .catch((error) => {
                                    setCommitting(false);
                                    notify.error(error, "collector.commit_collector_data_to_ledger");
                                });
                        }}
                    >
                        {config.i18n.button.commit_forecasts}
                    </LoadingButton>
                ) : null}
            </div>
        </AdvisorContainer>
    );
};

export default CollectorEntry;
