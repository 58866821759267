"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorEntryItemPermission = void 0;
/**
 * An enumeration.
 */
var CollectorEntryItemPermission;
(function (CollectorEntryItemPermission) {
    CollectorEntryItemPermission["READ_ONLY"] = "READ_ONLY";
    CollectorEntryItemPermission["READ_WRITE"] = "READ_WRITE";
    CollectorEntryItemPermission["BLANK"] = "BLANK";
})(CollectorEntryItemPermission || (exports.CollectorEntryItemPermission = CollectorEntryItemPermission = {}));
