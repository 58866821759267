import dayjs from "dayjs";
import DimensionBox from "../DimensionBox";
import React, { useContext } from "react";
import SelectField from "../field/SelectField";
import ClickableDatePicker from "../ClickableDatePicker";
import { Autocomplete, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { isForecastingEnabled } from "../../util/feature_flag_util";
import { convertToISODate } from "../../util/util";
import { AppContext } from "../../AppRouter";

const DateSelector = ({ disabled, date, dateAgg, annualize, cumulative, scenario, onUpdate }) => {
    const { config } = useContext(AppContext);

    const minDate = dayjs(date[0]);
    const maxDate = dayjs(date[1]);

    const historicalMin = dayjs(config.time.historical.min);
    const historicalMax = dayjs(config.time.historical.max);

    const referenceMin = dayjs(config.time.reference.min);
    const referenceMax = dayjs(config.time.reference.max);

    const maxValue = dayjs(config.time.global.max);

    function getStartDateSelector() {
        return (
            <ClickableDatePicker
                dataCyProp="startDate_selector"
                views={["year", "month"]}
                format="MMM YYYY"
                label={config.i18n.dimension.DATE_FROM}
                disabled={disabled}
                minDate={historicalMin}
                maxDate={historicalMax < maxDate ? historicalMax : maxDate}
                value={minDate}
                onAccept={val => onUpdate(val.startOf("month"), maxDate, scenario?.selected, scenario?.cutoff_date)}
            />
        );
    }

    function getEndDateSelector() {
        return (
            <ClickableDatePicker
                dataCyProp="endDate_selector"
                views={["year", "month"]}
                format="MMM YYYY"
                label={config.i18n.dimension.DATE_TO}
                disabled={disabled}
                minDate={minDate || historicalMin}
                maxDate={maxValue}
                value={maxDate}
                onAccept={val => onUpdate(minDate, val.endOf("month"), scenario?.selected, scenario?.cutoff_date)}
            />
        );
    }

    function getAllTimeDateSelector() {
        return (
            <Button
                data-cy="allTimeDate_button"
                variant="contained"
                color="grey"
                fullWidth
                disabled={disabled || (minDate.isSame(historicalMin) && maxDate.isSame(historicalMax))}
                onClick={() => onUpdate(historicalMin, historicalMax, scenario?.selected, scenario?.cutoff_date)}
            >
                {config.i18n.selector.all_date_button}
            </Button>
        );
    }

    function getReferenceDateSelector() {
        return (
            <Button
                data-cy="referenceDate_button"
                variant="contained"
                color="grey"
                fullWidth
                disabled={disabled || (minDate.isSame(referenceMin) && maxDate.isSame(referenceMax))}
                onClick={() => onUpdate(referenceMin, referenceMax, scenario?.selected, scenario?.cutoff_date)}
            >
                {config.i18n.selector.reference_date_button}
            </Button>
        );
    }

    function getAggDateSelector(param) {
        const value = param?.value || "";
        const options = param?.options || [];
        return (
            <SelectField
                dataCyProp="aggDate_selector"
                value={value}
                label={config.i18n.selector.date_agg}
                possibleValues={options}
                metadata={config.i18n.dimension}
                disabled={disabled || !param}
                onChange={value => param.onUpdate(value)}
            />
        );
    }

    function getAnnualizeSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="annualizeDate_checkbox"
                        checked={value}
                        disabled={disabled || param?.disabled || !param}
                        onChange={event => annualize.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.annualize}
            />
        );
    }

    function getCumulativeSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="cumulative_checkbox"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={event => param.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.cumulative}
            />
        );
    }

    function getScenarioSelector(param) {
        const selectedScenario = param?.selected && param?.options.some(el => el.id === param.selected.id) ? scenario?.selected : "";
        return (
            <Autocomplete
                label={config.i18n.selector.scenario}
                freeSolo
                forcePopupIcon
                value={selectedScenario}
                options={param?.options || []}
                getOptionLabel={option => option?.name || option}
                disabled={!isForecastingEnabled(config) || !param || disabled || isEmpty(param?.options)}
                size="small"
                onChange={(event, value) => {
                    let newMaxDate = maxDate;

                    // Check if the scenario was cleared
                    if (!selectedScenario) {
                        newMaxDate = maxDate < dayjs(config.time.global.max) ? maxDate : dayjs(config.time.global.max);
                    }

                    onUpdate(minDate, newMaxDate, value, param.cutoff_date);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={config.i18n.selector.scenario}
                        inputProps={{ ...params.inputProps, "readOnly": true, "data-cy": "scenario_autocomplete" }}
                    />
                )}
            />
        );
    }

    function getCutoffDateSelector(param) {
        const maxCutoffDate = config.time.forecasting ? dayjs(config.time.forecasting.min) : historicalMax;
        const cutoffDate = param?.cutoff_date ? dayjs(param.cutoff_date) : maxCutoffDate;

        return (
            <ClickableDatePicker
                dataCyProp="cutoff_date_selector"
                label={config.i18n.selector.cutoff_date}
                views={["year", "month"]}
                format="MMM YYYY"
                disabled={!isForecastingEnabled(config) || !param || disabled || isEmpty(param?.options) || !param.selected || !cutoffDate}
                disableFuture
                minDate={historicalMin}
                maxDate={maxCutoffDate}
                value={cutoffDate}
                onAccept={val => onUpdate(minDate, maxDate, param.selected, convertToISODate(val.startOf("month")))}
            />
        );
    }

    return (
        <DimensionBox title={config.i18n.customization_bar.date} collapsible>
            <Grid container spacing={1} rowSpacing={1.5} alignItems="center">
                <Grid item xs={6}>
                    {getStartDateSelector()}
                </Grid>
                <Grid item xs={6}>
                    {getEndDateSelector()}
                </Grid>
                <Grid item xs={6}>
                    {getAllTimeDateSelector()}
                </Grid>
                <Grid item xs={6}>
                    {getReferenceDateSelector()}
                </Grid>
                <Grid item xs={6}>
                    {getAggDateSelector(dateAgg)}
                </Grid>
                <Grid item xs={6}>
                    {getAnnualizeSelector(annualize)}
                </Grid>
                <Grid item xs={6}>
                    {getScenarioSelector(scenario)}
                </Grid>
                <Grid item xs={6}>
                    {getCutoffDateSelector(scenario)}
                </Grid>
                <Grid item xs={6}>
                    {getCumulativeSelector(cumulative)}
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

DateSelector.propTypes = {
    disabled: PropTypes.bool,
    date: PropTypes.array,
    defaultDate: PropTypes.array,
    defaultSetting: PropTypes.array,
    dateAgg: PropTypes.object,
    annualize: PropTypes.object,
    cumulative: PropTypes.object,
    scenario: PropTypes.object,
    onUpdate: PropTypes.func,
};

export default DateSelector;
