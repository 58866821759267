import React, { useContext } from "react";
import SelectField from "./SelectField";
import EditableTextField from "./EditableTextField";
import { formatCompactAmount, formatRatioToPercentage } from "../../util/formatter";
import { hasValue } from "../../util/util";
import PropTypes from "prop-types";
import ClickableDatePicker from "../ClickableDatePicker";
import dayjs from "dayjs";
import { AppContext } from "../../AppRouter";

const ValueField = ({ type, onChange, ...params }) => {
    const { config } = useContext(AppContext);

    switch (type) {
        case "SELECT":
            return <SelectField onChange={onChange} {...params} />;
        case "PERCENTAGE":
            return (
                <EditableTextField
                    type="number"
                    formatter={value => formatRatioToPercentage(value, config.locale)}
                    initialEditFormatter={value => formatRatioToPercentage(value, config.locale, false)}
                    onChange={value => onChange(hasValue(value) ? value / 100 : null)}
                    {...params}
                />
            );
        case "AMOUNT":
            return (
                <EditableTextField
                    type="number"
                    formatter={value => formatCompactAmount(value, config.locale)}
                    onChange={onChange}
                    {...params}
                />
            );
        case "NUMBER":
            return <EditableTextField type="number" onChange={onChange} {...params} />;
        case "DATE":
            if (params.value) {
                params.value = dayjs(params.value);
            }

            return (
                <ClickableDatePicker
                    {...params}
                    onAccept={onChange}
                    autoComplete="off"
                />
            );
        default:
            return <EditableTextField type="text" onChange={onChange} {...params} />;
    }
};

ValueField.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    params: PropTypes.object,
};

export default ValueField;
