"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Multiplier = void 0;
/**
 * An enumeration.
 */
var Multiplier;
(function (Multiplier) {
    Multiplier["AUTO"] = "AUTO";
    Multiplier["NONE"] = "NONE";
    Multiplier["THOUSAND"] = "THOUSAND";
    Multiplier["MILLION"] = "MILLION";
    Multiplier["BILLION"] = "BILLION";
})(Multiplier || (exports.Multiplier = Multiplier = {}));
