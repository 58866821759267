export const computeOperator = (values, operator) => {
    if (values.every(el => el == null || !isDecimalNumber(el)) || (values.some(el => el == null) && operator === "PRODUCT")) {
        return null;
    }

    switch (operator) {
        case "SUM":
            return values.reduce((a, b) => (Number(a) || 0) + (Number(b) || 0));
        case "PRODUCT":
            return values.reduce((a, b) => (Number(a) || 0) * (Number(b) || 0));
        case "DELTA":
            return values.reduce((a, b) => (Number(a) || 0) - (Number(b) || 0));
        case "AVERAGE":
            return values.reduce((a, b) => (Number(a) || 0) + (Number(b) || 0)) / values.length;
        case "RATIO":
            return values.reduce((a, b) => (Number(a) || 0) / (Number(b) || 0));
    }
};

export const isDecimalNumber = (value) => {
    return /^-?\d+(\.\d+)?$/.test(value);
};

export function toPrecisionWithTrailingZeros(num, significantDigits) {
    const numWithPrecision = num.toPrecision(significantDigits);

    if (numWithPrecision.includes("e")) {
        const [coefficient, exponent] = numWithPrecision.split("e");
        const exp = parseInt(exponent);
        const fixedCoefficient = parseFloat(coefficient).toFixed(significantDigits);

        if (exp >= 0) {
            return (parseFloat(fixedCoefficient) * Math.pow(10, exp)).toFixed(0);
        } else {
            return (parseFloat(fixedCoefficient) * Math.pow(10, exp)).toFixed(Math.abs(exp) + 3);
        }
    } else {
        return numWithPrecision;
    }
}
