"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var CollectorService = /** @class */ (function () {
    function CollectorService() {
    }
    /**
     * List Collectors
     * @param page
     * @param limit
     * @returns PaginatedResponse_Collector_ The collectors.
     * @throws ApiError
     */
    CollectorService.collectorListCollectors = function (page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Create Collector
     * @param requestBody
     * @returns Collector The created collector.
     * @throws ApiError
     */
    CollectorService.collectorCreateCollector = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/collector',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Collector
     * @param collectorId
     * @returns Collector The collector.
     * @throws ApiError
     */
    CollectorService.collectorGetCollector = function (collectorId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/{collector_id}',
            path: {
                'collector_id': collectorId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Update Collector
     * @param collectorId
     * @param name
     * @returns Collector The updated collector.
     * @throws ApiError
     */
    CollectorService.collectorUpdateCollector = function (collectorId, name) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/collector/{collector_id}',
            path: {
                'collector_id': collectorId,
            },
            query: {
                'name': name,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Delete Collector
     * @param collectorId
     * @returns Collector The deleted collector.
     * @throws ApiError
     */
    CollectorService.collectorDeleteCollector = function (collectorId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/collector/{collector_id}',
            path: {
                'collector_id': collectorId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Collector Template
     * @param collectorId
     * @returns CollectorTemplate The collector template.
     * @throws ApiError
     */
    CollectorService.collectorGetCollectorTemplate = function (collectorId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/template/get/{collector_id}',
            path: {
                'collector_id': collectorId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Collector Entries
     * @param collectorId
     * @param page
     * @param limit
     * @returns PaginatedResponse_CollectorEntry_ The collector's entries.
     * @throws ApiError
     */
    CollectorService.collectorListCollectorEntries = function (collectorId, page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/{collector_id}/entry',
            path: {
                'collector_id': collectorId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Commit Collector Data To Ledger
     * @param collectorId
     * @returns any
     * @throws ApiError
     */
    CollectorService.collectorCommitCollectorDataToLedger = function (collectorId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/collector/{collector_id}/commit',
            path: {
                'collector_id': collectorId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Sync Ledger Data To Collector
     * @param collectorId
     * @returns any
     * @throws ApiError
     */
    CollectorService.collectorSyncLedgerDataToCollector = function (collectorId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/collector/{collector_id}/sync',
            path: {
                'collector_id': collectorId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Collector Entry
     * @param collectorEntryId
     * @returns CollectorEntry The collector entry.
     * @throws ApiError
     */
    CollectorService.collectorGetCollectorEntry = function (collectorEntryId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Update Collector Entry Items
     * @param collectorEntryId
     * @param requestBody
     * @returns CollectorEntry
     * @throws ApiError
     */
    CollectorService.collectorUpdateCollectorEntryItems = function (collectorEntryId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Collector Entry Items
     * @param collectorEntryId
     * @returns CollectorEntryItem The collector entry's items.
     * @throws ApiError
     */
    CollectorService.collectorListCollectorEntryItems = function (collectorEntryId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}/item',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Collector Entry External Items
     * @param collectorEntryId
     * @returns CollectorEntryItem The collector entry's external items (from other entries).
     * @throws ApiError
     */
    CollectorService.collectorListCollectorEntryExternalItems = function (collectorEntryId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}/external_item',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Submit For Review Collector Entry
     * @param collectorEntryId
     * @returns any
     * @throws ApiError
     */
    CollectorService.collectorSubmitForReviewCollectorEntry = function (collectorEntryId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}/submit_for_review',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Approve Collector Entry
     * @param collectorEntryId
     * @returns any
     * @throws ApiError
     */
    CollectorService.collectorApproveCollectorEntry = function (collectorEntryId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}/approve',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Reject Collector Entry
     * @param collectorEntryId
     * @returns any
     * @throws ApiError
     */
    CollectorService.collectorRejectCollectorEntry = function (collectorEntryId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/collector/{collector_id}/entry/{collector_entry_id}/reject',
            path: {
                'collector_entry_id': collectorEntryId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Collector Template
     * @returns CollectorTemplate The list of collector templates.
     * @throws ApiError
     */
    CollectorService.collectorListCollectorTemplate = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/collector/template/list',
        });
    };
    /**
     * Clone Collector
     * @param collectorId
     * @param requestBody
     * @returns Collector The cloned collector.
     * @throws ApiError
     */
    CollectorService.collectorCloneCollector = function (collectorId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/collector/{collector_id}/clone',
            path: {
                'collector_id': collectorId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    return CollectorService;
}());
exports.CollectorService = CollectorService;
