"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementInitiativeSupport = void 0;
/**
 * An enumeration.
 */
var ProcurementInitiativeSupport;
(function (ProcurementInitiativeSupport) {
    ProcurementInitiativeSupport["CHAMPION"] = "CHAMPION";
    ProcurementInitiativeSupport["ACCEPT"] = "ACCEPT";
    ProcurementInitiativeSupport["REJECT"] = "REJECT";
})(ProcurementInitiativeSupport || (exports.ProcurementInitiativeSupport = ProcurementInitiativeSupport = {}));
