"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementInitiativeSlope = void 0;
/**
 * An enumeration.
 */
var ProcurementInitiativeSlope;
(function (ProcurementInitiativeSlope) {
    ProcurementInitiativeSlope["FLAT"] = "FLAT";
    ProcurementInitiativeSlope["RAMP"] = "RAMP";
})(ProcurementInitiativeSlope || (exports.ProcurementInitiativeSlope = ProcurementInitiativeSlope = {}));
