import React from "react";
import CircularLoading from "./CircularLoading";
import PropTypes from "prop-types";
import { Backdrop, Paper } from "@mui/material";

const ComponentLoading = ({ loading, label }) => {
    return (
        <Backdrop
            sx={{
                color: "#fff",
                zIndex: theme => theme.zIndex.appBar - 1,
                position: "absolute",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0)",
                backdropFilter: "blur(100px)" }}
            open={loading}
        >
            <Paper elevation={3} sx={{ px: 4, py: 2 }}>
                <CircularLoading label={label} />
            </Paper>
        </Backdrop>
    );
};

ComponentLoading.propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
};

export default ComponentLoading;
