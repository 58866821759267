"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectStatus = void 0;
/**
 * An enumeration.
 */
var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["NOT_READY"] = "NOT_READY";
    ProjectStatus["RUNNING"] = "RUNNING";
    ProjectStatus["READY"] = "READY";
    ProjectStatus["READY_WITH_ERROR"] = "READY_WITH_ERROR";
    ProjectStatus["ERROR"] = "ERROR";
})(ProjectStatus || (exports.ProjectStatus = ProjectStatus = {}));
