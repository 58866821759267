import { Alert, Box, Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import AdvisorContainer from "../../layout/AdvisorContainer";
import SelectField from "../../components/field/SelectField";
import { Link as RouterLink, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Markdown from "markdown-to-jsx";
import { AppContext } from "../../AppRouter";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import dayjs from "dayjs";

const CollectorDetails = ({ operation, title, button, icon, shouldValidate, loading, hasTemplate, onClick }) => {
    const { client, config, notify, user } = useContext(AppContext);

    const [name, setName] = useState("");
    const [templateOptions, setTemplateOptions] = useState([]);
    const [template, setTemplate] = useState(null);
    const [startDate, setStartDate] = useState(dayjs(config.time.reference.min).startOf("month"));
    const [endDate, setEndDate] = useState(dayjs(config.time.reference.max).startOf("month"));
    const [cutoffDate, setCutoffDate] = useState(dayjs(config.time.reference.max).startOf("month"));

    const { collectorId } = useParams(); // needed for clone

    // we use global period for Collector dates since we are forecasting values
    const minDate = dayjs(config.time.global.min).startOf("month");
    const maxDate = dayjs(config.time.global.max).startOf("month");

    useEffect(() => {
        if (collectorId) {
            client.collector.collectorGetCollector(collectorId)
                .then((collector) => {
                    setName(collector.name);
                    setStartDate(dayjs(collector.start_date));
                    setEndDate(dayjs(collector.end_date));
                    setCutoffDate(dayjs(collector.cutoff_date));

                    client.collector.collectorGetCollectorTemplate(collector.collector_template_id)
                        .then((collectorTemplate) => {
                            setTemplate(collectorTemplate);
                        })
                        .catch((error) => {
                            notify.error(error, "collector.template.fetch");
                        });
                })
                .catch((error) => {
                    notify.error(error, "collector.fetch");
                });
        }
    }, [collectorId]);

    useEffect(() => {
        if (user.permissions?.COLLECTOR?.CREATE) {
            client.collector.collectorListCollectorTemplate()
                .then((templates) => {
                    setTemplateOptions(templates);
                })
                .catch((error) => {
                    setTemplateOptions([]);
                    notify.error(error, "collector.template.fetch");
                });
        } else {
            setTemplateOptions([]);
        }
    }, []);

    return (
        <AdvisorContainer
            title={title}
            loading={!templateOptions}
            loadingLabel={config.i18n.collector.loading}
        >
            <Box
                component="form"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={2}
            >
                <TextField
                    required
                    fullWidth
                    disabled={!user.permissions?.COLLECTOR?.CREATE}
                    size="small"
                    data-cy="collector_details-name"
                    error={shouldValidate ? !name : null}
                    helperText={shouldValidate && !name ? config.i18n.warn.field_empty : ""}
                    label={config.i18n.collector.create.name}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                {hasTemplate
                    ? (
                        <SelectField
                            key="template-selector"
                            dataCyProp="collector_details-template_selector"
                            label={config.i18n.collector.create.template}
                            disabled={operation !== "create"}
                            value={template || ""}
                            possibleValues={templateOptions || []}
                            renderValue={value => value.name}
                            onChange={(e) => {
                                setTemplate(e);
                            }}
                            sx={{ flex: 1 }}
                        />
                        )
                    : null}
                { template
                    ? (
                        <Alert severity="info" style={{ width: "100%" }}>
                            <Markdown>
                                {template.description}
                            </Markdown>
                        </Alert>
                        )
                    : null}
               <Box display="flex" justifyContent="space-between" width="100%" mb={2} gap={2}>
                    {operation !== "edit" ? (
                        <>
                            <ClickableDatePicker
                                required
                                views={["year", "month"]}
                                format="MMM YYYY"
                                label={config.i18n.collector.start_date}
                                value={startDate}
                                minDate={minDate}
                                maxDate={endDate ? endDate : maxDate}
                                disabled={!user.permissions?.COLLECTOR?.CREATE}
                                error={shouldValidate && startDate === null}
                                helperText={shouldValidate && startDate === null ? config.i18n.warn.field_empty : ""}
                                onAccept={setStartDate}
                            />
                            <ClickableDatePicker
                                required
                                views={["year", "month"]}
                                format="MMM YYYY"
                                label={config.i18n.collector.end_date}
                                value={endDate}
                                minDate={startDate || minDate}
                                maxDate={maxDate}
                                disabled={!user.permissions?.COLLECTOR?.CREATE}
                                error={shouldValidate && endDate === null}
                                helperText={shouldValidate && endDate === null ? config.i18n.warn.field_empty : ""}
                                onAccept={setEndDate}
                            />
                            <ClickableDatePicker
                                required
                                views={["year", "month"]}
                                format="MMM YYYY"
                                label={config.i18n.collector.cutoff_date}
                                value={cutoffDate}
                                minDate={startDate || minDate}
                                maxDate={endDate || maxDate}
                                disabled={!user.permissions?.COLLECTOR?.CREATE}
                                error={shouldValidate && cutoffDate === null}
                                helperText={shouldValidate && cutoffDate === null ? config.i18n.warn.field_empty : ""}
                                onAccept={setCutoffDate}
                            />
                        </>
                    ) : null}
               </Box>
            </Box>
            <Box
                display="flex"
            >
                <Button
                    variant="contained"
                    color="grey"
                    disabled={loading}
                    sx={{ mr: 1 }}
                    title={config.i18n.button.cancel}
                    component={RouterLink}
                    to="/collector"
                >
                    {config.i18n.button.cancel}
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={loading}
                    loadingPosition="start"
                    data-cy="collector_details-save_button"
                    startIcon={icon}
                    title={button}
                    disabled={!user.permissions?.COLLECTOR?.CREATE}
                    onClick={() => onClick(name, template, startDate, endDate, cutoffDate)}
                >
                    {button}
                </LoadingButton>
            </Box>
        </AdvisorContainer>
    );
};

CollectorDetails.propTypes = {
    operation: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.any,
    icon: PropTypes.any,
    shouldValidate: PropTypes.bool,
    loading: PropTypes.bool,
    hasTemplate: PropTypes.bool,
    onClick: PropTypes.func,
};

export default CollectorDetails;
