"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementInitiativeLever = void 0;
/**
 * An enumeration.
 */
var ProcurementInitiativeLever;
(function (ProcurementInitiativeLever) {
    ProcurementInitiativeLever["PROCUREMENT_EXCELLENCE"] = "PROCUREMENT_EXCELLENCE";
    ProcurementInitiativeLever["TECHNICAL_PROCUREMENT"] = "TECHNICAL_PROCUREMENT";
    ProcurementInitiativeLever["DEMAND_MANAGEMENT"] = "DEMAND_MANAGEMENT";
    ProcurementInitiativeLever["COMMERCIAL_PROCUREMENT"] = "COMMERCIAL_PROCUREMENT";
})(ProcurementInitiativeLever || (exports.ProcurementInitiativeLever = ProcurementInitiativeLever = {}));
